import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { About, Contact, Featured, Hero, Jobs, Layout, Projects } from '@components';

const StyledMainContainer = styled.main`
  counter-reset: section;
`;

const IndexPage = function ({ location }) {
  return (
    <Layout location = {location}>
      <StyledMainContainer className = "fillHeight">
        <Hero />
        <About />
        <Jobs />
        <Featured />
        <Contact />
      </StyledMainContainer>
    </Layout>
  );
};

IndexPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default IndexPage;
